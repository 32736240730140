// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeepLinkButton_container__F5DYC {
    display: flex;
    align-items: center;
    justify-content: center;
}

.DeepLinkButton_deepLink__f3qTr {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: rgba(11, 129, 255, 0.1);
    border: 1px solid #0B81FF;
    border-radius: 4px;
    width: 150px;
    height: 25px;
}

a.DeepLinkButton_deepLink__f3qTr {
    color: #0B81FF;
}
`, "",{"version":3,"sources":["webpack://./src/components/DeepLinkButton/DeepLinkButton.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,mCAAmC;IACnC,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.deepLink {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    box-sizing: border-box;\n    background: rgba(11, 129, 255, 0.1);\n    border: 1px solid #0B81FF;\n    border-radius: 4px;\n    width: 150px;\n    height: 25px;\n}\n\na.deepLink {\n    color: #0B81FF;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DeepLinkButton_container__F5DYC`,
	"deepLink": `DeepLinkButton_deepLink__f3qTr`
};
export default ___CSS_LOADER_EXPORT___;
