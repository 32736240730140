// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputField_container__4PRO7 {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.InputField_inputFieldLabel__xxHdW {
    text-align: left;
    color: rgba(48, 48, 48, 0.8);
    width: 327px;
    height: 12px;
    margin-bottom: 6px;
}

.InputField_inputField__Fh7D0 {   /* using plain input {} will influence other components  */
    width: 302px;
    height: 21px;
    border: none;
    outline: none;
    display: flex;
    align-items: flex-end;
    text-align: left !important;
}

.InputField_inputField__Fh7D0:focus::placeholder {
    color: transparent !important;
}

.InputField_enabled__s4kIB {
    color: rgba(48, 48, 48, 0.8);
    border: 1px solid #C4C5CA;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 13px 9px 12px;
    width: 327px;
    height: 40px
}
`, "",{"version":3,"sources":["webpack://./src/components/InputField/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,gBAAgB;IAChB,4BAA4B;IAC5B,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA,kCAAgB,0DAA0D;IACtE,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,aAAa;IACb,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;IAC5B,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;IACtB,2BAA2B;IAC3B,YAAY;IACZ;AACJ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n}\n\n.inputFieldLabel {\n    text-align: left;\n    color: rgba(48, 48, 48, 0.8);\n    width: 327px;\n    height: 12px;\n    margin-bottom: 6px;\n}\n\n.inputField {   /* using plain input {} will influence other components  */\n    width: 302px;\n    height: 21px;\n    border: none;\n    outline: none;\n    display: flex;\n    align-items: flex-end;\n    text-align: left !important;\n}\n\n.inputField:focus::placeholder {\n    color: transparent !important;\n}\n\n.enabled {\n    color: rgba(48, 48, 48, 0.8);\n    border: 1px solid #C4C5CA;\n    border-radius: 8px;\n    box-sizing: border-box;\n    padding: 10px 13px 9px 12px;\n    width: 327px;\n    height: 40px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `InputField_container__4PRO7`,
	"inputFieldLabel": `InputField_inputFieldLabel__xxHdW`,
	"inputField": `InputField_inputField__Fh7D0`,
	"enabled": `InputField_enabled__s4kIB`
};
export default ___CSS_LOADER_EXPORT___;
